.r-wrapper {
    background-color: #f5f5f5;
    padding: 40px 0;
  }
  
  .r-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .r-head {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .residency-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .residency-image {
    width: 100%;
    height: auto;
    max-width: 600px;
    margin-bottom: 15px;
  }
  
  .residency-details {
    padding: 10px;
  }
  
  .residency-price {
    font-size: 18px;
    font-weight: bold;
    color: #4CAF50;
  }
  