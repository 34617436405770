/* About.css */
.about-section {
  height: 100vh; /* Full viewport height */
  display: flex; /* Use Flexbox for alignment */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: #f9f9f9; /* Optional: Set a background color */
  padding: 20px; /* Add some padding */
  box-sizing: border-box; /* Include padding in height calculation */
}

.about-container {
  display: flex; /* Flexbox layout */
  align-items: center; /* Center items vertically within the container */
  max-width: 1200px; /* Optional: Limit the width */
  margin: 0 auto; /* Center the container */
  text-align: left; /* Align text to the left */
}

.about-image {
  flex: 1; /* Allow the image section to grow */
  margin-right: 20px; /* Space between image and text */
}

.about-image img {
  width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: Rounded corners */
}

.about-content {
  flex: 1; /* Allow the text section to grow */
  padding: 20px; /* Optional: Add padding */
}

.about-title {
  font-size: 2rem; /* Title size */
  margin-bottom: 10px; /* Space below title */
}

.about-description,
.about-vision,
.about-mission {
  font-size: 1.2rem; /* Font size for description and vision/mission */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 10px; /* Space between paragraphs */
}
